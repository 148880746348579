
import Vue from 'vue'
import Component from 'vue-class-component'
import {Action, Getter} from "vuex-class";

// Define the component in class-style
@Component
export default class HomeView extends Vue {
  @Getter isElectron!: boolean;
  @Getter isDevMode!: boolean;
  @Getter isEicraproDevMode!: boolean;
  @Action checkUyapSession!: any;

  signRunning = false;
  signBusy = true;

  deneme() {
    this.$http.get('/api/v1/task')
  }

  mounted() {
    this.heartbeat();
    if (this.isDevMode) {
      this.$nextTick(() => {
        //this.checkUyapSession();
      });
    }
  }

  openEicrapro() {
    this.$http.get('https://imza.eicrapro.com:6090/openBrowser?url=https://www.eicrapro.com/giris.html#giris');
  }

  openNewEicrapro() {
    this.$http.get('https://imza.eicrapro.com:6090/openBrowser?url=https://cloud.zirve-bilgisayar.com/eicrapro');
  }

  async onStartClick() {
    this.signBusy = true;
    await this.$store.dispatch('startSignService');
    await this.$store.dispatch('startProxyService');
    await this.heartbeat();
    if (this.isDevMode) {
      this.$nextTick(() => {
        this.checkUyapSession();
      });
    }
  }

  async onStopClick() {
    this.signBusy = true;
    await this.$store.dispatch('stopSignService');
    await this.$store.dispatch('stopProxyService');
    await this.heartbeat();
  }

  async onRestartClick() {
    await this.onStopClick();
    await this.onStartClick();
  }

  async heartbeat() {
    this.signRunning = await this.$store.dispatch('checkSignService');
    this.signBusy = false;
  }

  exit() {
    console.log('exit');
    // if(window.zirvelib)
    //   window.zirvelib.signManager.kill();
  }
}
