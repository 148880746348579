
import {Component, Vue,} from "vue-property-decorator";
import {Getter, Action} from "vuex-class";

@Component({
  components: {}
})
export default class UyapConnectionInfo extends Vue {
  @Getter isUyapConnected!: boolean;
  @Getter isDevMode!: boolean;
  @Action connectToUyap!: any;
  @Getter isTokenExis!: boolean;

  openUyap() {
    this.$store.dispatch('openUyapWindow');
  }
}
