
import Vue from 'vue';
import {Action, Getter} from "vuex-class";

import {Component} from "vue-property-decorator";
import LoadingView from "@/views/LoadingView.vue";
import DownloadView from "@/views/DownloadView.vue";
import HomeView from "@/views/HomeView.vue";
import {IslemDurum, PlayerDurum} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import {Socket} from 'vue-socket.io-extended'
import UyapConnectionInfo from "@/infos/UyapConnectionInfo.vue";
import {AvukatKisiselBilgileriSorgulamaCevap} from "@/plugins/uyap-plugin/uyap/AvukatKisiselBilgileriSorgulama";
import {ITenant} from "@/types";
const { zirvelib } = window;

@Component({
  components: {UyapConnectionInfo, DownloadView, LoadingView, HomeView}
})
export default class App extends Vue {
  @Getter isUpdate!: boolean;
  @Getter isElectron!: boolean;
  @Getter isBrowser!: boolean;
  @Getter isUpdating!: boolean;
  @Getter isLoading!: boolean;
  @Getter uyapAvukatBilgileri!: AvukatKisiselBilgileriSorgulamaCevap;
  @Getter tenantBilgileri!: ITenant;
  @Getter isTokenExis!: boolean;
  @Action load!: () => Promise<void>;

  @Socket('player')
  onPlayer(player: any) {
    this.$store.commit("setPlayerDurum", Number(player));
  }

  @Socket('updateTasks')
  onUpdateTask() {
    this.$store.dispatch('updateTasks');
  }

  @Socket('connectToUyap')
  onConnectToUyap() {
    this.$store.dispatch('connectToUyap');
  }

  @Socket('openUyapWindow')
  onOpenUyapWindow() {
    this.$store.dispatch('openUyapWindow');
  }

  @Socket('updateToken')
  onUpdateToken() {
    if (!this.$store.getters.isTokenExis) {
      this.$store.state.tokenExis = true;
      // @ts-ignore
      this.$store.commit('setTenant', JSON.parse(sessionStorage.getItem('tenant')));
      this.$store.dispatch('getTasksFromServer').then(() => {
        this.$store.dispatch('taskInterval');
      });
    }
  }

  barokartBakiyeYukle() {
    window.open('https://barokart.com.tr/TL_Yukle.aspx', '_blank');
  }

  async mounted() {
    await this.$router.push('/islemler');
    await this.load();
    this.$socket.client.emit('player', PlayerDurum.dur);
    this.$store.dispatch('getTasksFromServer').then(() => {
      this.$store.dispatch('taskInterval');
    });
    zirvelib?.onAppCloseRequest(async () => {
      try {
        if (this.$store.getters.getIslemDurum == IslemDurum.ISLENIYOR)
          await this.$http.get('/api/v1/task/islemleri-durdur');
      } finally {
        zirvelib?.sendAppCloseResponse();
      }
    });
  }
}
