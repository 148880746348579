
import {Component, Vue} from "vue-property-decorator";
import {Getter} from "vuex-class";

const {zirvelib} = window;

enum EnvType {
  win,
  Macx64,
  MacArm64
}

enum Durum {
  BOSTA,
  INDIRILIYOR,
  INDIRDI_BASARILI,
  INDIRDI_BASARISIZ,
  KURULUYOR,
  KURULDU_BASARILI,
  KURULDU_BASARISIZ
}

interface IDownloadStatus {
  indirilenByte: number,
  toplamByte: number,
  indirmeYuzdesi: number
}

@Component({
  computed: {
    Durum() {
      return Durum;
    }
  }
})
export default class DownloadView extends Vue {
  @Getter isUpdate!: boolean;

  progress: IDownloadStatus = {
    indirilenByte: 0,
    toplamByte: 0,
    indirmeYuzdesi: 0
  };
  durum = {
    indirme: Durum.BOSTA,
    kurma: Durum.BOSTA
  };
  güncelVersiyon = 'v1.2.3'
  envs = [
    {
      type: EnvType.win,
      url: 'https://cdn.zirve-bilgisayar.com/imza/1.2.3/zirve-bilgisayar-e-imza-1-2-3.exe'
    },
    {
      type: EnvType.Macx64,
      url: 'https://cdn.zirve-bilgisayar.com/imza/1.2.3/zirve-bilgisayar-e-imza-1-2-3.dmg'
    },
    {
      type: EnvType.MacArm64,
      url: 'https://cdn.zirve-bilgisayar.com/imza/1.2.3/zirve-bilgisayar-e-imza-1-2-3-arm64.dmg'
    }
  ];

  get currentVersion() {
    return window.zirvelib?.version;
  }

  mounted() {
    this.startDownload();
  }

  async startDownload() {
    const os = this.getOS();
    const env = this.envs.find(e => e.type === os);
    if (!env) {
      console.error('Unsupported OS');
      return;
    }
    this.durum.indirme = Durum.INDIRILIYOR;
    zirvelib?.startDownload(env.url).then((filePath: any) => {
      console.log('File downloaded to:', filePath);
      this.durum.indirme = Durum.INDIRDI_BASARILI;
      this.durum.kurma = Durum.KURULUYOR;
      setTimeout(() => {
        zirvelib?.send('execute-and-quit', filePath);
      }, 1000);
    }).catch((error: any) => {
      this.durum.indirme = Durum.INDIRDI_BASARISIZ;
      console.error('Download failed:', error);
    })
    zirvelib?.onDownloadProgress((progress: IDownloadStatus) => {
      this.progress = progress;
    });
  }

  getOS(): EnvType {
    const userAgent = window.navigator.userAgent;
    if (userAgent.includes('Windows')) {
      return EnvType.win;
    } else if (userAgent.includes('Mac')) {
      if (zirvelib?.arch === 'x64') {
        return EnvType.Macx64;
      } else if (zirvelib?.arch === 'arm64') {
        return EnvType.MacArm64;
      }
    }
    throw new Error('Unsupported OS');
  }
}
