"use strict";

import app from "@/main";
import axios from "axios";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.baseURL = "https://imza.eicrapro.com:6090/";
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
let config = {
    baseURL: "https://imza.eicrapro.com:6090/"
    // timeout: 60 * 1000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);
_axios.defaults.headers.common['Content-Type'] = "application/x-www-form-urlencoded";
_axios.interceptors.request.use(
    function (config) {
        // @ts-ignore
        if (!sessionStorage.getItem('apiType') && config.url.includes('/api'))
            return Promise.reject(new Error("İcraProWeb'e Bağlanın"));
        // Do something before request is sent
        document.body.style.cursor = 'wait';
        let token = window.sessionStorage.getItem('accessToken');
        let tenant_id = window.sessionStorage.getItem('tenant_id');
        if (token) {
            // @ts-ignore
            config.headers['Authorization'] = token;
            if (tenant_id) {
                // @ts-ignore
                config.headers['Tenant-Id'] = tenant_id;
            }
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);


// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
        document.body.style.cursor = 'default';
        return response.data;
    },
    function (error) {
        document.body.style.cursor = 'default';
        if (error.response.status == 401 && sessionStorage.getItem('accessToken')) {
            app.$store.state.tokenExis = false;
            sessionStorage.removeItem('accessToken');
        }
        if (error.response.status != 401)
            app.$toast.error(error.message);
        return Promise.reject(error);
    }
);

export function AxiosPlugin<AxiosPlugOptions>(Vue: any): void {
    // do stuff with options
    Vue.prototype.$http = _axios;
}

export class AxiosPluginOptions {
    // add stuff
}
